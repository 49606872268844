// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-games-js": () => import("./../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-games-pop-the-balloon-js": () => import("./../src/pages/games/pop-the-balloon.js" /* webpackChunkName: "component---src-pages-games-pop-the-balloon-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-data-policy-js": () => import("./../src/pages/policy/data-policy.js" /* webpackChunkName: "component---src-pages-policy-data-policy-js" */),
  "component---src-pages-policy-privacy-policy-js": () => import("./../src/pages/policy/privacy-policy.js" /* webpackChunkName: "component---src-pages-policy-privacy-policy-js" */),
  "component---src-pages-pop-mdx": () => import("./../src/pages/pop.mdx" /* webpackChunkName: "component---src-pages-pop-mdx" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

